.squish {
  animation: squish 2s ease-in infinite;
  position: absolute;  /* 让两张图片叠在一起 */
}

@keyframes squish {
  5% {
    transform: scale(1.2, 0.9);
  }
  10% {
    transform: scale(0.9, 1.2) translate(0, -4px);
  }
  15% {
    transform: scale(1);
  }
}

.visible {
  opacity: 1;
  transition: opacity 0.5s;  /* 可以调整淡入的速度 */
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s;  /* 可以调整淡出的速度 */
}
