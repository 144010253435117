.rounded-button {
  border-radius: 10px;
  border: 2px solid #4f6ba7;
  background: #fff;
  transition: all 0.2s ease;
}

.rounded-button:hover {
  background: #ccc;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 4px;
  cursor: pointer;
}

.rounded-button:active {
  background: #e4e4e7;
}

.rounded-button-wordcloud-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded-button-wordcloud {
  color: #4664a3;
  font-weight: 700;
  font-size: 1rem;
  font-family: Inter;

  width: 15vw;
  height: 5.5vh;
}

.rounded-button-save {
  color: #4664a3;
  font-weight: 700;
  font-family: Inter;

  width: 15vw;
  height: 5vh;
}

@media (max-width: 768px) {
  .rounded-button-wordcloud {
    color: #4664a3;
    font-weight: 700;
    font-size: 1.3vh;
    font-family: Inter;

    width: 40vw;
    height: 2.5vh;
  }

  .rounded-button-save {
    color: #4664a3;
    font-weight: 700;
    font-family: Inter;

    width: 40vw;
    height: 3vh;
  }
}
