
@media (min-width: 768px) {
.savebutton {
    position: absolute;
    /* position: fixed; */
    top:2vh;
    left: 1vw;
    height: auto;
    width: 8vw;
    cursor: pointer;
}
}

@media (max-width: 768px) {
    .savebutton {
        position: absolute;
        top:2vh;
        left: 4vw;
        height: auto;
        width: 20vw;
        cursor: pointer;
    }
    }