@media (min-width: 768px) {
/* body {
    margin: 0;
    padding: 0;
} */
 
/* .container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: url(../img/background.jpg);
    background-attachment: fixed;
} */
.container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: url(../img/cover/background.jpg) no-repeat center center fixed;
    background-size: cover;
  }
  
  
  
  .animated-text {
    position: absolute;
    font-size: 7vh;
    color: white;
    font-weight: 600;
    text-align: center;
    animation: glow 3s infinite;
    top: 35vh;
    left: 26vw;
    height: 30vh;
}

.animated-text-2 {
    position: absolute;
    font-size: 5vh;
    color: white;
    font-weight: 600;
    text-align: center;
    animation: glow 3s infinite;
    text-align: left;
    top: 33vh;
    left: 26vw;
}

@keyframes glow {
    0% {
        text-shadow: 0 0 10px rgb(0, 238, 255), 0 0 20px #00f, 0 0 30px #00f, 0 0 40px #1e90ff;
    }
    50% {
        text-shadow: 0 0 20px rgb(0, 238, 255), 0 0 30px rgb(0, 238, 255), 0 0 40px #00f, 0 0 50px #1e90ff, 0 0 60px #1e90ff, 0 0 70px #1e90ff;
    }
    100% {
        text-shadow: 0 0 10px rgb(0, 238, 255), 0 0 20px #00f, 0 0 30px #00f, 0 0 40px #1e90ff;
    }
}




@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Adjust other styles as needed */
.animate {
  animation: typing 3s steps(40) forwards;
}

}

@media (max-width: 768px) {
  /* body {
      margin: 0;
      padding: 0;
  } */
   
  /* .container {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background: url(../img/background.jpg);
      background-attachment: fixed;
  } */
  .container {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      background: url(../img/mobile_cover/background.png) no-repeat center center fixed;
      background-size: cover;
    }
    
    
    
    .animated-text {
      position: absolute;
      font-size: 4vh;
      color: white;
      font-weight: 600;
      text-align: center;
      animation: glow 3s infinite;
      top: 38vh;
      left: 10vw;
      height: 30vh;
  }
  
  .animated-text-2 {
      position: absolute;
      font-size: 2.6vh;
      color: white;
      font-weight: 600;
      text-align: center;
      animation: glow 3s infinite;
      text-align: left;
      line-height: 1.5;
      top: 33vh;
      left: 10vw;
      right: 10vw;
  }
  
  @keyframes glow {
      0% {
          text-shadow: 0 0 10px rgb(0, 238, 255), 0 0 20px #00f, 0 0 30px #00f, 0 0 40px #1e90ff;
      }
      50% {
          text-shadow: 0 0 20px rgb(0, 238, 255), 0 0 30px rgb(0, 238, 255), 0 0 40px #00f, 0 0 50px #1e90ff, 0 0 60px #1e90ff, 0 0 70px #1e90ff;
      }
      100% {
          text-shadow: 0 0 10px rgb(0, 238, 255), 0 0 20px #00f, 0 0 30px #00f, 0 0 40px #1e90ff;
      }
  }
  
  
  
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  /* Adjust other styles as needed */
  .animate {
    animation: typing 3s steps(40) forwards;
  }
  
  }
  
