.daynight-toggle-container {
    position: relative;
    margin-left: 1vw;
    width: 180em;
    height: 70em;
    transform: translate3d(0, 0, 0);
  }
  .components {
    position: fixed;
    width: 180em;
    height: 70em;
    background-color: rgba(70, 133, 192, 1);
    border-radius: 100em;
    box-shadow: inset 0 0 5em 3em rgba(0, 0, 0, 0.5);
    overflow: hidden;
    transition: 0.7s;
    transition-timing-function: cubic-bezier(0, 0.5, 1, 1);
    cursor: pointer;
  }
  .components-light {
    background-color: rgba(70, 133, 192, 1);
  }
  .components-dark {
    background-color: rgba(25, 30, 50, 1);
  }
  
  .main-button {
    margin: 7.5em 0 0 7.5em;
    width: 55em;
    height: 55em;
    background-color: rgba(255, 195, 35, 1);
    border-radius: 50%;
    box-shadow: 3em 3em 5em rgba(0, 0, 0, 0.5),
      inset -3em -5em 3em -3em rgba(0, 0, 0, 0.5),
      inset 4em 5em 2em -2em rgba(255, 230, 80, 1);
    transition: 1s;
    transition-timing-function: cubic-bezier(0.56, 1.35, 0.52, 1);
  }
  
  .main-button-light {
    transform: translateX(0);
    background-color: rgba(255, 195, 35, 1);
    box-shadow: 3em 3em 5em rgba(0, 0, 0, 0.5),
      inset -3em -5em 3em -3em rgba(0, 0, 0, 0.5),
      inset 4em 5em 2em -2em rgba(255, 230, 80, 1);
  }
  
  .main-button-dark {
    transform: translateX(110em);
    background-color: rgba(195, 200, 210, 1);
    box-shadow: 3em 3em 5em rgba(0, 0, 0, 0.5),
      inset -3em -5em 3em -3em rgba(0, 0, 0, 0.5),
      inset 4em 5em 2em -2em rgba(255, 255, 210, 1);
  }
  
  .main-button-light-hover {
    transform: translateX(10em);
  }
  
  .main-button-dark-hover {
    transform: translateX(100em);
  }
  
  .moon {
    position: absolute;
    background-color: rgba(150, 160, 180, 1);
    box-shadow: inset 0em 0em 1em 1em rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    transition: 0.5s;
    opacity: 0;
  }
  .moon-light:nth-child(1) {
    opacity: 0;
  }
  .moon-light:nth-child(2) {
    opacity: 0;
  }
  .moon-light:nth-child(3) {
    opacity: 0;
  }
  .moon-dark:nth-child(1) {
    opacity: 1;
  }
  .moon-dark:nth-child(2) {
    opacity: 1;
  }
  .moon-dark:nth-child(3) {
    opacity: 1;
  }
  .moon:nth-child(1) {
    top: 7.5em;
    left: 25em;
    width: 12.5em;
    height: 12.5em;
  }
  .moon:nth-child(2) {
    top: 20em;
    left: 7.5em;
    width: 20em;
    height: 20em;
  }
  .moon:nth-child(3) {
    top: 32.5em;
    left: 32.5em;
    width: 12.5em;
    height: 12.5em;
  }
  .daytime-background {
    position: absolute;
    border-radius: 50%;
    transition: 1s;
    transition-timing-function: cubic-bezier(0.56, 1.35, 0.52, 1);
  }
  
  .daytime-background-light:nth-child(2) {
    transform: translateX(0);
  }
  .daytime-background-light:nth-child(3) {
    transform: translateX(0);
  }
  .daytime-background-light:nth-child(4) {
    transform: translateX(0);
  }
  
  .daytime-background-dark:nth-child(2) {
    transform: translateX(110em);
  }
  .daytime-background-dark:nth-child(3) {
    transform: translateX(80em);
  }
  .daytime-background-dark:nth-child(4) {
    transform: translateX(50em);
  }
  
  .daytime-background-dark-hover:nth-child(2) {
    transform: translateX(100em);
  }
  .daytime-background-dark-hover:nth-child(3) {
    transform: translateX(73em);
  }
  .daytime-background-dark-hover:nth-child(4) {
    transform: translateX(46em);
  }
  
  .daytime-background-light-hover:nth-child(2) {
    transform: translateX(10em);
  }
  .daytime-background-light-hover:nth-child(3) {
    transform: translateX(7em);
  }
  .daytime-background-light-hover:nth-child(4) {
    transform: translateX(4em);
  }
  
  .daytime-background:nth-child(2) {
    top: -20em;
    left: -20em;
    width: 110em;
    height: 110em;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -2;
  }
  .daytime-background:nth-child(3) {
    top: -32.5em;
    left: -17.5em;
    width: 135em;
    height: 135em;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: -3;
  }
  .daytime-background:nth-child(4) {
    top: -45em;
    left: -15em;
    width: 160em;
    height: 160em;
    background-color: rgba(255, 255, 255, 0.05);
    z-index: -4;
  }
  .cloud {
    z-index: -2;
  }
  .cloud-back {
    position: absolute;
    right: 0em;
    bottom: 25em;
    opacity: 0.5;
    z-index: -3; /*transform: rotate(-5deg);*/
  }
  .cloud,
  .cloud-back {
    transform: translateY(10em);
    transition: 1s;
    transition-timing-function: cubic-bezier(0.56, 1.35, 0.52, 1);
  }
  
  .cloud-light,
  .cloud-back-light {
    transform: translateY(10em);
  }
  .cloud-dark,
  .cloud-back-dark {
    transform: translateY(80em);
  }
  .cloud-son {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    z-index: -1;
    transition: transform 6s, right 1s, bottom 1s;
  }
  .cloud-son:nth-child(6n + 1) {
    right: -20em;
    bottom: 10em;
    width: 50em;
    height: 50em;
  }
  .cloud-son:nth-child(6n + 2) {
    right: -10em;
    bottom: -25em;
    width: 60em;
    height: 60em;
  }
  .cloud-son:nth-child(6n + 3) {
    right: 20em;
    bottom: -40em;
    width: 60em;
    height: 60em;
  }
  .cloud-son:nth-child(6n + 4) {
    right: 50em;
    bottom: -35em;
    width: 60em;
    height: 60em;
  }
  .cloud-son:nth-child(6n + 5) {
    right: 75em;
    bottom: -60em;
    width: 75em;
    height: 75em;
  }
  .cloud-son:nth-child(6n + 6) {
    right: 110em;
    bottom: -50em;
    width: 60em;
    height: 60em;
  }
  
  .cloud-son-hover:nth-child(6n + 1) {
    right: -24em;
    bottom: 10em;
  }
  .cloud-son-hover:nth-child(6n + 2) {
    right: -12em;
    bottom: -27em;
  }
  .cloud-son-hover:nth-child(6n + 3) {
    right: 17em;
    bottom: -43em;
  }
  .cloud-son-hover:nth-child(6n + 4) {
    right: 46em;
    bottom: -39em;
  }
  .cloud-son-hover:nth-child(6n + 5) {
    right: 70em;
    bottom: -65em;
  }
  .cloud-son-hover:nth-child(6n + 6) {
    right: 109em;
    bottom: -54em;
  }
  
  .cloud-son-2-hover:nth-child(6n + 1) {
    right: -23em;
    bottom: 10em;
  }
  .cloud-son-2-hover:nth-child(6n + 2) {
    right: -11em;
    bottom: -26em;
  }
  .cloud-son-2-hover:nth-child(6n + 3) {
    right: 18em;
    bottom: -42em;
  }
  .cloud-son-2-hover:nth-child(6n + 4) {
    right: 47em;
    bottom: -38em;
  }
  .cloud-son-2-hover:nth-child(6n + 5) {
    right: 74em;
    bottom: -64em;
  }
  .cloud-son-2-hover:nth-child(6n + 6) {
    right: 110em;
    bottom: -55em;
  }
  
  .stars {
    transform: translateY(-125em);
    z-index: -2;
    transition: 1s;
    transition-timing-function: cubic-bezier(0.56, 1.35, 0.52, 1);
  }
  .stars-light {
    transform: translateY(-125em);
    opacity: 0;
  }
  .stars-dark {
    transform: translateY(-62.5em);
    opacity: 1;
  }
  .big {
    --size: 7.5em;
  }
  .medium {
    --size: 5em;
  }
  .small {
    --size: 3em;
  }
  .star {
    position: absolute;
    width: calc(2 * var(--size));
    height: calc(2 * var(--size));
  }
  .star:nth-child(1) {
    top: 11em;
    left: 39em;
    animation-name: star;
    animation-duration: 3.5s;
  }
  .star:nth-child(2) {
    top: 39em;
    left: 91em;
    animation-name: star;
    animation-duration: 4.1s;
  }
  .star:nth-child(3) {
    top: 26em;
    left: 19em;
    animation-name: star;
    animation-duration: 4.9s;
  }
  .star:nth-child(4) {
    top: 37em;
    left: 66em;
    animation-name: star;
    animation-duration: 5.3s;
  }
  .star:nth-child(5) {
    top: 21em;
    left: 75em;
    animation-name: star;
    animation-duration: 3s;
  }
  .star:nth-child(6) {
    top: 51em;
    left: 38em;
    animation-name: star;
    animation-duration: 2.2s;
  }
  .star-hover:nth-child(1) {
    top: 10em;
    left: 36em;
  }
  .star-hover:nth-child(2) {
    top: 40em;
    left: 87em;
  }
  .star-hover:nth-child(3) {
    top: 26em;
    left: 16em;
  }
  .star-hover:nth-child(4) {
    top: 38em;
    left: 63em;
  }
  .star-hover:nth-child(5) {
    top: 20.5em;
    left: 72em;
  }
  .star-hover:nth-child(6) {
    top: 51.5em;
    left: 35em;
  }
  @keyframes star {
    0%,
    20% {
      transform: scale(0);
    }
    20%,
    100% {
      transform: scale(1);
    }
  }
  .star-son {
    float: left;
  }
  .star-son:nth-child(1) {
    --pos: left 0;
  }
  .star-son:nth-child(2) {
    --pos: right 0;
  }
  .star-son:nth-child(3) {
    --pos: 0 bottom;
  }
  .star-son:nth-child(4) {
    --pos: right bottom;
  }
  .star-son {
    width: var(--size);
    height: var(--size);
    background-image: radial-gradient(
      circle var(--size) at var(--pos),
      transparent var(--size),
      #fff
    );
  }
  .star {
    transform: scale(1);
    transition-timing-function: cubic-bezier(0.56, 1.35, 0.52, 1);
    transition: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }
  .twinkle {
    transform: scale(0);
  }
  