@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');


@media (min-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

::selection {
    background: rgba(0, 0, 0, .2);
}

body {
    background: #f9f9f9;
    min-height: 100vh;
    overflow-x: hidden;
}

header_demo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
}

.logo_demo {
    font-size: 2em;
    color: #359381;
    pointer-events: none;
    margin-right: 270px;
}

.navigation a {
    text-decoration: none;
    color: #359381;
    padding: 6px 15px;
    border-radius: 20px;
    margin: 0 10px;
    font-weight: 600;
}

.navigation a:hover,
.navigation a.active {
    background: #359381;
    color: #fff;
}

.parallax {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../img/paraScroll_demo/Back.png');
    background-size: cover;
}

#text {
    position: absolute;
    font-size: 5vh;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
}

.parallax img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
}

.sec {
    position: relative;
    /* background: #003329; */
    background: url('../img/paraScroll_demo/2background.jpg');
    background-size: cover;
    padding: 7vh;
    height: 100vh;
}

.sec h2 {
    font-size: 6vh;
    color: #fff;
    margin-bottom: 10px;
    align-items: end;
}

.sec h3 {
  font-size: 4.5vh;
  color: #fff;
  margin-bottom: 10px;
}

.sec p {
    font-size: 1em;
    color: #fff;
    font-weight: 300;
}

.fixed-box1 {
    position: absolute;
    top: 20vh; /* 相对于容器顶部的距离 */
    left: 5vw; /* 相对于容器左侧的距离 */
    width: 33.7vw; /* 设置方块的宽度 */
    height: 40vh; /* 设置方块的高度 */
    background: url('../img/paraScroll_demo/mission1.png');
    background-size: 100% auto;
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }

  .fixed-box2 {
    position: absolute;
    top: 50vh; /* 相对于容器顶部的距离 */
    left: 20vw; /* 相对于容器左侧的距离 */
    width: 42vw; /* 设置方块的宽度 */
    height: 43vh; /* 让高度自动适应 */
    background: url('../img/paraScroll_demo/mission2.png');
    background-size: 100% auto;
    background-repeat: no-repeat; /* 防止背景图片重复 */
}



  .fixed-box3 {
    position: absolute;
    top: 30vh; /* 相对于容器顶部的距离 */
    left: 60vw; /* 相对于容器左侧的距离 */
    width: 33vw; /* 设置方块的宽度 */
    height: 40vh; /* 设置方块的高度 */
    background: url('../img/paraScroll_demo/mission3.png');
    background-size: 100% auto;
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }

  .message-box {
    position: absolute;
    top: 10vh; /* 相对于容器顶部的距离 */
    left: 100vw; /* 相对于容器左侧的距离 */
    height: 14vh;
    width: 40vw;
    cursor: pointer;
  }
  
  .cat {
    position: absolute;
    top: 10vh;
    left: 60vw;
    height: 1vh;
    width: 1vh;
    visibility: hidden;
  }
  
  .box-1 {
    z-index: 1; /* 设置方块的层级，较高的层级会覆盖较低的层级 */
  }
  
  .box-2 {
    z-index: 3;
  }
  
  .box-3 {
    z-index: 2;
  }

  .icon-div1 {
    display: flex;
    position: absolute;
    top: 30%; /* 相对于容器顶部的距离 */
    aspect-ratio: 1/1; /* 设置宽高比为1:2 */
    justify-content: flex-start;
    left: 10% ;
    width:25% ;
    text-align: end;
    align-items: flex-end;
  }

  .icon-div3 {
    display: flex;
    position: absolute;
    top: 30%; /* 相对于容器顶部的距离 */
    aspect-ratio: 1/1; /* 设置宽高比为1:2 */
    justify-content: flex-start;
    left: 10% ;
    width:25% ;
    text-align: end;
    align-items: flex-end;
  }

  .icon-div2 {
    display: flex;
    position: absolute;
    aspect-ratio: 1/1; /* 设置宽高比为1:2 */
    left: 60%;
    width:15% ;
  }

  .text-div2-2 {
    display: flex;
    position: absolute;
    left: 80%;
  }

  .text-div1 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 30%; /* 相对于容器顶部的距离 */
    right: 10%; /* 相对于容器左侧的距离 */
    bottom: 20%;
    letter-spacing: 2px;
    color: #fff;
    text-align: end;
    align-items: flex-end;
  }
  
  .text-div2 {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 60%; /* 相对于容器顶部的距离 */
    left: 12%; /* 相对于容器左侧的距离 */
    letter-spacing: 2px;
    color: #fff;
    text-align: start;
    align-items: flex-start;
  }
  


  
  /* ParaScroll.css */

.overlay {
  position: fixed; /* Full screen and above other content */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent white */
  display: flex; /* Center the child div */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1000; /* Make sure it's on top of everything else */
}

.popup-image-container {
  position: relative; /* For positioning the close button absolutely */
  width: 50vw; /* Width of the image container */
  height: 30vh; /* Height of the image container */
  left: 2vw;
  /* Additional styles if needed */
}

.close-button {
  position: absolute;
  top: 0; /* Position it on the top-right */
  right: 3vw;
  cursor: pointer; /* Change cursor to indicate it's clickable */
  width: 5vw; /* Width of the close button */
  height: 5vw; /* Height of the close button */
  /* Add any additional styling for close button */
}

}


@media (max-width: 768px) {
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Poppins', sans-serif;
  }
  
  ::selection {
      background: rgba(0, 0, 0, .2);
  }
  
  body {
      background: #f9f9f9;
      min-height: 100vh;
      overflow-x: hidden;
  }
  
  header_demo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 30px 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 100;
  }
  
  .logo_demo {
      font-size: 2em;
      color: #359381;
      pointer-events: none;
      margin-right: 270px;
  }
  
  .navigation a {
      text-decoration: none;
      color: #359381;
      padding: 6px 15px;
      border-radius: 20px;
      margin: 0 10px;
      font-weight: 600;
  }
  
  .navigation a:hover,
  .navigation a.active {
      background: #359381;
      color: #fff;
  }
  
  .parallax {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background: url('../img/paraScroll_demo/Back.png');
      background-size: cover;
  }
  
  #text {
      position: absolute;
      font-size: 5vh;
      color: #fff;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
  }
  
  .parallax img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
  }
  
  .sec {
      position: relative;
      /* background: #003329; */
      background: url('../img/paraScroll_demo/2background.jpg');
      background-size: cover;
      padding: 7vh;
      height: 100vh;
  }
  
  .sec h2 {
      font-size: 4vh;
      color: #fff;
      margin-bottom: 10px;
  }
  
  .sec h3 {
    font-size: 3vh;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .sec p {
      font-size: 1em;
      color: #fff;
      font-weight: 300;
  }
  
  .fixed-box1 {
      position: absolute;
      top: 30vh; /* 相对于容器顶部的距离 */
      left: 5vw; /* 相对于容器左侧的距离 */
      width: 70vw; /* 设置方块的宽度 */
      height: 30vh; /* 设置方块的高度 */
      background: url('../img/paraScroll_demo/mission1.png');
      background-size: 100% auto;
      background-repeat: no-repeat; /* 防止背景图片重复 */
    }
  
    .fixed-box2 {
      position: absolute;
      top: 70vh; /* 相对于容器顶部的距离 */
      left: 5vw; /* 相对于容器左侧的距离 */
      width: 80vw; /* 设置方块的宽度 */
      height: 43vh; /* 让高度自动适应 */
      background: url('../img/paraScroll_demo/mission2.png');
      background-size: 100% auto;
      background-repeat: no-repeat; /* 防止背景图片重复 */
  }
  
  
  
    .fixed-box3 {
      position: absolute;
      top: 48vh; /* 相对于容器顶部的距离 */
      left: 15vw; /* 相对于容器左侧的距离 */
      width: 80vw; /* 设置方块的宽度 */
      height: 40vh; /* 设置方块的高度 */
      background: url('../img/paraScroll_demo/mission3.png');
      background-size: 100% auto;
      background-repeat: no-repeat; /* 防止背景图片重复 */
    }
  
    .message-box {
      position: absolute;
      top: 20vh; /* 相对于容器顶部的距离 */
      left: 100vw; /* 相对于容器左侧的距离 */
      height: 7vh;
      width: 80vw;
      cursor: pointer;
    }
    
    .cat {
      position: absolute;
      top: 20vh;
      left: 32vw;
      height: 1vw;
      width: 1vw;
      visibility: hidden;
    }
    
    .box-1 {
      z-index: 1; /* 设置方块的层级，较高的层级会覆盖较低的层级 */
    }
    
    .box-2 {
      z-index: 3;
    }
    
    .box-3 {
      z-index: 2;
    }
  
    .icon-div1 {
      display: flex;
      position: absolute;
      top: 20%; /* 相对于容器顶部的距离 */
      aspect-ratio: 1/1; /* 设置宽高比为1:2 */
      justify-content: flex-start;
      left: 10% ;
      width:30% ;
      text-align: end;
      align-items: flex-end;
    }

    .icon-div3 {
      display: flex;
      position: absolute;
      top: 20%; /* 相对于容器顶部的距离 */
      aspect-ratio: 1/1; /* 设置宽高比为1:2 */
      justify-content: flex-start;
      left: 10% ;
      width:25% ;
      text-align: end;
      align-items: flex-end;
      margin-right: 5vw;
    }
  
    .icon-div2 {
      display: flex;
      position: absolute;
      aspect-ratio: 1/1; /* 设置宽高比为1:2 */
      left: 60%;
      width:15% ;
    }
  
    .text-div2-2 {
      display: flex;
      position: absolute;
      left: 50%;
    }
  
    .text-div1 {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 20%; /* 相对于容器顶部的距离 */
      right: 10%; /* 相对于容器左侧的距离 */
      letter-spacing: 2px;
      color: #fff;
      text-align: end;
      align-items: flex-end;
      font-size: 4vw;
    }
    
    .text-div2 {
      display: flex;
      justify-content: flex-start;
      position: absolute;
      top: 20%; /* 相对于容器顶部的距离 */
      left: 10%; /* 相对于容器左侧的距离 */
      letter-spacing: 2px;
      color: #fff;
      text-align: start;
      align-items: flex-start;
    }
    
  
  
    
    /* ParaScroll.css */
  
  .overlay {
    position: fixed; /* Full screen and above other content */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent white */
    display: flex; /* Center the child div */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* Make sure it's on top of everything else */
  }
  
  .popup-image-container {
    position: relative; /* For positioning the close button absolutely */
    width: 95vw; /* Width of the image container */
    height: 50vh; /* Height of the image container */
    top: 5vh;
    left: 8vw;
    /* Additional styles if needed */
  }
  
  .close-button {
    position: absolute;
    top: 0vh; /* Position it on the top-right */
    right: 15vw;
    cursor: pointer; /* Change cursor to indicate it's clickable */
    width: 10vw; /* Width of the close button */
    height: 10vw; /* Height of the close button */
    /* Add any additional styling for close button */
  }
  
  }