@media (min-width: 768px) {
.personal2special_container {
    /* background-image: url('../img/personal1/BG_stars.png'); 
    background-size: 100vw 100vh;  */
    background-size: cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }


  .personal2special_container .title_container {
    position: absolute;
    top: 8vh; /* 相对于容器顶部的距离 */
    left: 10vw; /* 相对于容器左侧的距离 */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 2.5vh;
    color: white;
  }
  
  /* 图片和标题的样式 */
  .personal2special_container .title_container .header {
    display: flex;
    align-items: 'flex-end';
  }


  .personal2special_container .outer_container {
    position: absolute;
    top: 15vh; /* 相对于容器顶部的距离 */
    left: 10vw; /* 相对于容器左侧的距离 */
    right: 10vw;
    bottom: 5vh;
    background-image: url('../img/personal2/Panel.png'); 
    background-size: 80vw 80vh; 
    flex-direction: column;
    align-items: flex-start;
    font-size: 1rem;
    color: white;
  }

  .personal2special_container .outer_container .inner_container {
    position: absolute;
    top: 5vh; /* 相对于容器顶部的距离 */
    left: 5vw; /* 相对于容器左侧的距离 */
    right: 5vw;
    bottom: 5vh;

  }

  .personal2special_container .outer_container .inner_container .inner_bottom_container {
    position: absolute;
    top:20vh; /* 相对于容器顶部的距离 */
    bottom: 0vh;
    width: 100%;
    background-image: url('../img/Personal2Special/spliter_2.png'); 
    background-size: cover; 
    padding-left: -10vw;
    padding-top: 10vh;
    display: flex;
  }

  .personal2special_container .outer_container .inner_container .inner_bottom_container  h4{
    position: absolute;
    top: 46vh; /* 相对于容器顶部的距离 */
    left: 10vw;
    font-size: 4vh;
    color: white;
  }

  .personal2special_container .outer_container .inner_container .inner_bottom_container  h5{
    position: absolute;
    top: 19vh; /* 相对于容器顶部的距离 */
    left: 45vw;
    font-size: 4vh;
    color: white;
  }


  .personal2special_container .cat_container {
    position: absolute;
    top: 62vh; /* 相对于容器顶部的距离 */
    left: 81vw; /* 相对于容器左侧的距离 */
    width: 20vw;
    cursor: pointer;
  }

  .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiuBG {
    position: absolute;
    top: 19vh; /* 相对于容器顶部的距离 */
    left: 45vw;
    background-image: url('../img/Personal2Special/cat1.png'); 
    background-size: cover; 
  }

  .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiu_cat1 {
    width: 35vw;
    height: 12vw;
    background-image: url('../img/Personal2Special/cat1.png');
    background-size: cover;
  }

  .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiu_cat1 h4{
    position: relative;
    top: 31%; /* 相对于容器顶部的距离 */
    left: 30%;
    font-size: 150%;
    color: white;
  }

  .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiu_cat2 {
    width: 30vw;
    height: 11vw;
    background-image: url('../img/Personal2Special/cat2.png');
    background-size: cover;
  }

  .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiu_cat2  h5{
    position: relative;
    top: 30%; /* 相对于容器顶部的距离 */
    left: 60%;
    font-size: 130%;
    color: white;
  }
}


@media (max-width: 768px) {
  .personal2special_container {
      /* background-image: url('../img/personal1/BG_stars.png'); 
      background-size: 100vw 100vh;  */
      background-size: cover;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      position: relative;
    }
  
  
    .personal2special_container .title_container {
      position: absolute;
      top: 8vh; /* 相对于容器顶部的距离 */
      left: 10vw; /* 相对于容器左侧的距离 */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 1.3vh;
      color: white;
    }
    
    /* 图片和标题的样式 */
    .personal2special_container .title_container .header {
      display: flex;
      align-items: 'flex-end';
    }
  
  
    .personal2special_container .outer_container {
      position: absolute;
      top: 12vh; /* 相对于容器顶部的距离 */
      left: 4vw; /* 相对于容器左侧的距离 */
      right: 4vw;
      bottom: 3vh;
      background-image: url('../img/personal2/panel_mobile.png'); 
      background-size: 92vw 85vh; 
      flex-direction: column;
      align-items: flex-start;
      font-size: 1rem;
      color: white;
    }
  
    .personal2special_container .outer_container .inner_container {
      position: absolute;
      top: 5vh; /* 相对于容器顶部的距离 */
      left: 5vw; /* 相对于容器左侧的距离 */
      right: 5vw;
      bottom: 5vh;
    }
  
    .personal2special_container .outer_container .inner_container .inner_bottom_container {
      position: absolute;
      top:10vh; /* 相对于容器顶部的距离 */
      bottom: 0vh;
      width: 100%;
      background-image: url('../img/personal2/Box_mobile.png'); 
      background-size: cover; 
      padding-left: -10vw;
      padding-top: 7vh;
      display: flex;
    }
  
    .personal2special_container .outer_container .inner_container .inner_bottom_container  h4{
      position: absolute;
      top: 46vh; /* 相对于容器顶部的距离 */
      left: 10vw;
      font-size: 4vh;
      color: white;
    }
  
    .personal2special_container .outer_container .inner_container .inner_bottom_container  h5{
      position: absolute;
      top: 19vh; /* 相对于容器顶部的距离 */
      left: 45vw;
      font-size: 4vh;
      color: white;
    }
  
  
    .personal2special_container .cat_container {
      position: absolute;
      top: 83vh; /* 相对于容器顶部的距离 */
      left: 70vw; /* 相对于容器左侧的距离 */
      width: 60vw;
      cursor: pointer;
      z-index: 0;
    }
  
    .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiuBG {
      position: absolute;
      top: 19vh; /* 相对于容器顶部的距离 */
      left: 45vw;
      background-image: url('../img/Personal2Special/cat1.png'); 
      background-size: cover; 
    }

    .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiu_cat1 {
      width: 80vw;
      height: 25vw;
      background-image: url('../img/Personal2Special/cat1.png');
      background-size: cover;
    }

    .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiu_cat1 h4{
      position: relative;
      top: 31%; /* 相对于容器顶部的距离 */
      left: 30%;
      font-size: 100%;
      color: white;
    }

    .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiu_cat2 {
      width: 80vw;
      height: 27vw;
      background-image: url('../img/Personal2Special/cat2.png');
      background-size: cover;
    }

    .personal2special_container .outer_container .inner_container .inner_bottom_container .baoxiu_cat2  h5{
      position: relative;
      top: 30%; /* 相对于容器顶部的距离 */
      left: 60%;
      font-size: 100%;
      color: white;
    }
  }